import "./CloudAnimation.css";
import State from "../state";

import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";

function CloudAnimation() {
	const visible = State.cloudAnimationActive;
	const [unloading, setUnloading] = useState(false);

	useEffect(() => {
		if (visible) {
			setUnloading(true);
			return;
		}

		const timeout = setTimeout(() => setUnloading(false), 2100);
		return () => clearTimeout(timeout);
	}, [visible]);

	if (!visible && !unloading) {
		return null;
	}

	return (
		<div className={classnames("CloudAnimation", { visible })}>
			<img
				className="CloudAnimation-img"
				src={require("../media/animation/cloud1.png").default}
				alt=""
			/>
			<img
				className="CloudAnimation-img"
				src={require("../media/animation/cloud2.png").default}
				alt=""
			/>
			<img
				className="CloudAnimation-img"
				src={require("../media/animation/cloud3.png").default}
				alt=""
			/>
			<img
				className="CloudAnimation-img"
				src={require("../media/animation/cloud4.png").default}
				alt=""
			/>
			<img
				className="CloudAnimation-img"
				src={require("../media/animation/cloud5.png").default}
				alt=""
			/>
		</div>
	);
}

export default observer(CloudAnimation);
