import "./MainCard.css";
import HomePage from "./HomePage";
import UserPage from "./UserPage";
import State from "../state";
import { USERS } from "../config";

import classnames from "classnames";
import { observer } from "mobx-react-lite";
import { Redirect } from "react-router-dom";

function MainCard({ userId }) {
	const flipped = !!userId;
	const animated = State.cloudAnimationActive;

	if (userId && !USERS[userId]) {
		return <Redirect to="/" />;
	}

	return (
		<div className={classnames("MainCard", { animated })}>
			<div className={classnames("MainCard-inner", { flipped })}>
				<div className="MainCard-front">
					<HomePage />
				</div>
				<div className="MainCard-back">
					<UserPage userId={userId} />
				</div>
			</div>
		</div>
	);
}

export default observer(MainCard);
