import "./App.css";
import MainCard from "./components/MainCard";
import CloudAnimation from "./components/CloudAnimation";
import RedirectMessage from "./components/RedirectMessage";
import State from "./state";

import Widget from "@faintlines/widget";
import "@faintlines/widget/dist/index.css";

import { Router, Route } from "react-router-dom";
import { observer } from "mobx-react-lite";

function App({ history }) {
    return (
        <div className="App">
            <Router history={history}>
                <Route
                    path={["/", "/link/:userId"]}
                    exact
                    render={({ match }) => {
                        const userId = match?.params?.userId?.toLowerCase();
                        return <MainCard userId={userId} />;
                    }}
                />
            </Router>
            <CloudAnimation />
            <WidgetWrapper />
            <RedirectMessage />
        </div>
    );
}

export default App;

const WidgetWrapper = observer(() => (
    <Widget
        config={{
            dialogTitle: "Hello, Wizard!",
            position: "bottomRight",
            chat: {
                method: "iframe",
                iframeSrc:
                    "https://tawk.to/chat/6087a0775eb20e09cf36e5b8/1f48slkq1",
            },
        }}
        context={{ active: State.activeCoversation }}
        storyConfig={{ id: "wizards", assetId: "video_call" }}
    />
));
