import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import {
	initAnalytics,
	webVitalsEvent,
	initSentry,
} from "@faintlines/monitoring";

import React from "react";
import ReactDOM from "react-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { createBrowserHistory } from "history";

const theme = createMuiTheme({
	// typography: {
	// 	fontFamily: [
	// 		"Grandstander",
	// 		"-apple-system",
	// 		"BlinkMacSystemFont",
	// 		"Segoe UI",
	// 		"Roboto",
	// 		"Oxygen",
	// 		"Ubuntu",
	// 		"Cantarell",
	// 		"Fira Sans",
	// 		"Droid Sans",
	// 		"Helvetica Neue",
	// 		"sans-serif",
	// 	].join(","),
	// },
	palette: {
		primary: { main: "#8f4bea" },
	},
});

initSentry({
	dsn: "https://959da8ce1cf447b0ac078553bf85dcbd@o574655.ingest.sentry.io/5725924",
});

const history = createBrowserHistory();

initAnalytics({ trackingId: "UA-194968253-1", history });

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<App history={history} />
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(webVitalsEvent);
