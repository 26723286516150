export const DEBUG = process.env.NODE_ENV !== "production";

export const USERS = {
	plott: {
		main: true,
		videoUrls: {
			file: "https://advusercontent.com/wizards/video-chat-single-3.mp4",
			hls:
				"https://stream.mux.com/Fv13rdCIgIvqbM6TV2ZQydPbTF01JN2YVV00LtPs02001ww.m3u8",
			// dash: "https://videodelivery.net/425050cf4b94f910b9df4f76fd4b93e1/manifest/video.mpd",
		},
		videoAspectRatio: 720 / 850,
		participantNames: ["The Great Wizard", "Plott"],
		onEnd: {
			redirect:
				"https://adventurestore.me/ending/wizards/ac471bfe-aa57-4b65-a3fe-8b57a2ca69ef",
		},
		spell: {
			gesture: "sign4",
			keywords: [
				"bright",
				"flickers",
				"flicker",
				"swap",
				"liquors",
				"liquor",
			],
			minKeywords: 2,
			hintText: "Use a spell to switch the drinks",
			hintLongText:
				"Hurry up! The Great Wizard is about to drink the poisonous drink! Use a spell to switch between the drinks before it’s too late!",
			hintDelay: 60,
		},
	},
};
