import "./UserPage.css";
import Logo from "./Logo";
import VideoChatSingleVideo from "./VideoChatSingleVideo";
import { textContainsWords, capitalize } from "../utils";
import { voiceState } from "../voice";
import State from "../state";
import { USERS } from "../config";

import { VoiceListener } from "@faintlines/voice-kit";
import GestureRecognizer from "@faintlines/gestures";

import { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Howl } from "howler";

function UserPage({ userId }) {
	const [busy, setBusy] = useState(true);
	const [ended, setEnded] = useState(false);

	const magicSoundRef = useRef(
		new Howl({
			src: [require("../media/spell1.mp3").default],
		})
	);

	useEffect(() => {
		if (!userId) {
			setBusy(true);
		}
	}, [userId]);

	function handleEnter() {
		magicSoundRef.current.play();
		State.toggleCloudAnimation(true);
		State.setActiveConversation(userId);
		setTimeout(() => setBusy(false), 3000);
		setTimeout(() => State.toggleCloudAnimation(false), 4000);
	}

	function handleConversationEnd() {
		setEnded(true);
		State.setUnavailableUser(userId);
		State.setActiveConversation(null);

		const { redirect } = USERS[userId].onEnd || {};
		if (redirect) {
			State.redirect(redirect);
		}
	}

	function handleSpellActivation() {
		if (USERS[userId].main) {
			State.toggleFinalSpellActivated(true);
		}
	}

	return (
		<div className="UserPage">
			<Logo linkTo="/" />
			<UserPageContents
				userId={userId}
				busy={busy}
				unavailable={State.unavailableUsers[userId]}
				ended={ended}
				onEnter={handleEnter}
				onSpellActivated={handleSpellActivation}
				onConversationEnd={handleConversationEnd}
			/>
		</div>
	);
}

export default observer(UserPage);

function UserPageContents({
	userId,
	unavailable,
	busy,
	ended,
	onEnter,
	onSpellActivated,
	onConversationEnd,
}) {
	if (!userId) {
		return null;
	}

	const user = USERS[userId];
	if (!user) {
		return null;
	}

	if (ended) {
		return (
			<UnavailableMessage
				title="Telepathy Session Ended"
				userId={userId}
			/>
		);
	}

	if (unavailable) {
		return <UnavailableMessage title="Unavailable" userId={userId} />;
	}

	if (busy) {
		return <BusyMessage userId={userId} onEnter={onEnter} />;
	}

	return (
		<VideoChatSingleVideo
			videoUrls={user.videoUrls}
			videoAspectRatio={user.videoAspectRatio}
			participantNames={user.participantNames}
			playText={`Click to join ${capitalize(
				userId
			)}'s live telepathy session`}
			spell={user.spell}
			onSpellActivated={onSpellActivated}
			onEnd={onConversationEnd}
		/>
	);
}

function BusyMessage({ userId, onEnter }) {
	const [detectGestures, setDetectGestures] = useState(false);
	const userName = capitalize(userId);

	useEffect(() => {
		const timeout = setTimeout(() => setDetectGestures(true), 1000);
		return () => clearTimeout(timeout);
	}, []);

	return (
		<div className="UserPage-busy">
			<div className="UserPage-busy-title">
				{`Looks like ${userName} is currently in another telepathy session`}
			</div>
			<div className="UserPage-busy-subtitle">
				{"Feel free to try again later"}
			</div>
			<img
				className="UserPage-busy-image"
				alt="Busy"
				src={require("../media/brain-busy.svg").default}
			/>
			{detectGestures ? (
				<>
					<VoiceListener
						voiceState={voiceState}
						predicate={_spellActivated}
						stopOnSuccess={true}
						onSuccess={onEnter}
					/>
					<GestureRecognizer
						gestures={["sign3"]}
						onGesture={onEnter}
					/>
				</>
			) : null}
		</div>
	);
}

function UnavailableMessage({ title, userId }) {
	return (
		<div className="UserPage-busy">
			<div className="UserPage-busy-title">{title}</div>
			<div className="UserPage-busy-subtitle">
				{`Looks like ${capitalize(
					userId
				)} is no longer available for telepathy.`}
			</div>
			<img
				className="UserPage-busy-image"
				alt="Busy"
				src={require("../media/brain-unavailable.svg").default}
			/>
		</div>
	);
}

function _spellActivated(text) {
	return textContainsWords(
		text,
		["spectral", "cloud", "hear", "them", "loud"],
		2
	);
}
