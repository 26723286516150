import styles from "./RedirectMessage.module.css";
import State from "../state";

import { observer } from "mobx-react-lite";

const RedirectModal = observer(() => {
	const { redirectUrl } = State;
	if (!redirectUrl) {
		return null;
	}

	return (
		<div className={styles.root}>
			<div className={styles.wrapper}>
				<div className={styles.card}>
					<a href={redirectUrl} className={styles.link}>
						{
							"You are being redirected, please click here if your browser does not redirect you."
						}
					</a>
				</div>
			</div>
		</div>
	);
});

export default RedirectModal;
