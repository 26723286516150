import "./Logo.css";

import { Link } from "react-router-dom";

export default function Logo({ linkTo }) {
	const contents = (
		<>
			<img
				className="Logo-image"
				src={require("../media/logo.svg").default}
				alt="TelepathyLink"
			/>
			<span className="Logo-text">{"TelepathyLink"}</span>
		</>
	);

	if (linkTo) {
		return (
			<Link className="Logo" to={linkTo}>
				{contents}
			</Link>
		);
	}
	return <div className="Logo">{contents}</div>;
}
