import styles from "./GlassSwitchAnimation.module.css";

import classnames from "classnames";

export default function GlassSwitchAnimation() {
	return (
		<div className={classnames(styles.animation)}>
			<div
				className={classnames(
					styles.animation__flare,
					styles.animation__flare1
				)}
			/>
			<div
				className={classnames(
					styles.animation__flare,
					styles.animation__flare2
				)}
			/>
		</div>
	);
}
