import "./HomePage.css";
import Logo from "./Logo";
import { USERS } from "../config";
import { loginAttemptEvent } from "../analytics";

import { TextField, Button } from "@material-ui/core";
import { useState } from "react";
import { useHistory } from "react-router-dom";

export default function HomePage() {
	return (
		<div className="HomePage">
			<Logo />
			<div className="HomePage-contents">
				<h1 className="HomePage-title">
					{"Telepathize Like It's 2021!"}
				</h1>
				<p className="HomePage-subtitle">
					{"Tired of juggling between telepathy requests? " +
						"Telepathy Link ensures only one wizard can communicate with you at any given time!"}
				</p>
				<HomePageForm />
			</div>
			<div className="HomePage-hero">
				<img src={require("../media/brain.svg").default} alt="" />
			</div>
		</div>
	);
}

function HomePageForm() {
	const [userId, setUserId] = useState(_getDefaultUserIdFromHash());
	const [error, setError] = useState(null);
	const history = useHistory();

	function submitHandler(evt) {
		evt.preventDefault();
		if (!userId) {
			setError("Required");
			return;
		}

		const normUserId = userId.trim().toLowerCase();

		loginAttemptEvent(normUserId);

		if (!Object.keys(USERS).includes(normUserId)) {
			setError("No such wizard");
			return;
		}

		setError("");
		history.push(`/link/${normUserId}`);
	}

	return (
		<form
			className="HomePage-form"
			noValidate
			autoComplete="off"
			onSubmit={submitHandler}
		>
			<TextField
				label="Wizard Name"
				placeholder="e.g. Merlin"
				variant="outlined"
				error={!!error}
				helperText={error}
				value={userId}
				onChange={(evt) => setUserId(evt.target.value)}
				InputLabelProps={{
					shrink: true,
				}}
			/>
			<Button
				variant="contained"
				color="primary"
				className="HomePage-form-button"
				onClick={submitHandler}
			>
				{"Telepathize!"}
			</Button>
		</form>
	);
}

function _getDefaultUserIdFromHash() {
	const hash = window.location.hash;
	return hash.length > 1 ? hash.replace("#", "") : "";
}
