import { makeAutoObservable, toJS, autorun } from "mobx";
import store from "store";

const PERSIST_STORE_KEY = "state";
const PERSIST_KEYS = [];

class State {
	// UI
	cloudAnimationActive = false;
	redirectUrl = null;

	// Functionality
	unavailableUsers = {};
	activeCoversation = null;

	// Mission
	finalSpellActivated = false;

	constructor() {
		const existingState = store.get(PERSIST_STORE_KEY);
		if (existingState) {
			PERSIST_KEYS.forEach((key) => {
				if (existingState[key]) {
					this[key] = existingState[key];
				}
			});
		}

		makeAutoObservable(this);

		autorun(() => {
			const persistedState = {};
			PERSIST_KEYS.forEach(
				(key) => (persistedState[key] = toJS(this[key]))
			);
			store.set(PERSIST_STORE_KEY, persistedState);
		});
	}

	reset() {
		this.unavailableUsers = {};
		this.finalSpellActivated = false;
	}

	toggleCloudAnimation(toggle) {
		this.cloudAnimationActive = toggle;
	}

	setUnavailableUser(userId) {
		this.unavailableUsers[userId] = true;
	}

	toggleFinalSpellActivated(toggle) {
		this.finalSpellActivated = toggle;
	}

	setActiveConversation(userId) {
		this.activeCoversation = userId;
	}

	redirect(url) {
		this.redirectUrl = url;
		setTimeout(() => {
			window.location.href = url;
		}, 2000);
	}
}

const state = new State();

export default state;
