import styles from "./VideoChatSingleVideo.module.css";
import GlassSwitchAnimation from "./GlassSwitchAnimation";
import { voiceState } from "../voice";
import { textContainsWords } from "../utils";

import VideoPlayer from "@faintlines/video-player";
import { VoiceListener } from "@faintlines/voice-kit";
import GestureRecognizer from "@faintlines/gestures";

import { useRef, useState, useEffect, useCallback } from "react";
import classnames from "classnames";
import { Howl } from "howler";

const AUTO_SPELL_TIME_SEC = 150;

export default function VideoChatSingleVideo({
	videoUrls,
	videoAspectRatio,
	playText,
	participantNames,
	onEnd,
	spell,
	onSpellActivated,
}) {
	const videoRef = useRef();
	const wrpperRef = useRef();
	const [playing, setPlaying] = useState(false);
	const [size, setSize] = useState(undefined);
	const [animate, setAnimate] = useState(false);

	function doubleClickHandler() {
		const video = videoRef.current;
		if (video) {
			video.seekTo(AUTO_SPELL_TIME_SEC - 5);
		}
	}

	function playingHandler() {
		setPlaying(true);
	}

	const spellActivatedHandler = useCallback(
		function () {
			setAnimate(true);
			setTimeout(() => {
				new Howl({
					src: [require("../media/spell2.mp3").default],
					volume: 0.5,
				}).play();
			}, 500);
			onSpellActivated && onSpellActivated();
		},
		[onSpellActivated]
	);

	function calcSize() {
		const wrapper = wrpperRef.current;

		const sizeFactor = 0.95;
		const width = wrapper.clientWidth * sizeFactor;
		const height = wrapper.clientHeight * sizeFactor;
		const wrapperAspectRatio = width / height;
		if (wrapperAspectRatio > videoAspectRatio) {
			setSize({
				height: Math.round(height),
				width: Math.round(height * videoAspectRatio),
			});
		} else {
			setSize({
				height: Math.round(width / videoAspectRatio),
				width: Math.round(width),
			});
		}
	}

	useEffect(() => {
		calcSize();
		window.addEventListener("resize", calcSize);

		return () => {
			window.removeEventListener("resize", calcSize);
		};
	}, []); // eslint-disable-line

	useEffect(() => {
		const interval = setInterval(() => {
			const video = videoRef.current;
			if (!animate && video && video.currentTime >= AUTO_SPELL_TIME_SEC) {
				spellActivatedHandler();
			}
		}, 500);

		return () => clearInterval(interval);
	}, [animate, spellActivatedHandler]);

	const canTriggerSpell = !!spell && playing && !animate;

	return (
		<div
			className={styles.chat}
			ref={wrpperRef}
			onDoubleClick={doubleClickHandler}
		>
			<div
				className={classnames(
					styles.sizeHelper,
					playing ? styles.playing : null
				)}
				style={size}
			>
				{size ? (
					<>
						<VideoPlayer
							ref={videoRef}
							url={videoUrls}
							className={styles.streamWrapper}
							autoPlay
							preload="auto"
							width={size.width}
							height={size.height}
							onEnded={onEnd}
							onPlay={playingHandler}
						/>
						{participantNames ? (
							<ParticipantNames
								name1={participantNames[0]}
								name2={participantNames[1]}
							/>
						) : null}
					</>
				) : null}
				{animate ? <GlassSwitchAnimation /> : null}
			</div>
			<StartPlayingButton
				text={playText}
				visible={!playing}
				onClick={() => videoRef.current?.play()}
			/>
			{canTriggerSpell ? (
				<SpellListener
					spell={spell}
					onSpellActivated={spellActivatedHandler}
				/>
			) : null}
		</div>
	);
}

function ParticipantNames({ name1, name2 }) {
	return (
		<div className={styles.names}>
			<div className={styles.names__name1}>{name1}</div>
			<div className={styles.names__name2}>{name2}</div>
		</div>
	);
}

function SpellListener({ spell, onSpellActivated }) {
	const { gesture, keywords, minKeywords } = spell;

	function spellActivationPredicate(text) {
		return textContainsWords(text, keywords, minKeywords);
	}

	return (
		<>
			{gesture ? (
				<GestureRecognizer
					gestures={[gesture]}
					onGesture={onSpellActivated}
				/>
			) : null}
			{keywords ? (
				<VoiceListener
					voiceState={voiceState}
					predicate={spellActivationPredicate}
					stopOnSuccess={true}
					onSuccess={onSpellActivated}
				/>
			) : null}
		</>
	);
}

function StartPlayingButton({ text, visible, onClick }) {
	return (
		<div
			className={classnames(
				styles.startButton,
				visible ? null : styles.hidden
			)}
			onClick={onClick}
		>
			{text}
		</div>
	);
}
